<template>
  <div class="dynamic-banner" :style="bannerStyle">
    <CustomLink class="dynamic-banner__wrapper" :to="info?.url ?? '/'" @click.native="selectItemEvent(info, index)">
      <CustomImage
        v-if="image.id"
        :media="mediaImage"
        :image-id="image.id"
        :aspect-ratio="image.ratio"
        :width="image.sizes[0]"
        :height="image.sizes[1]"
        :alt="info?.name"
        :loading="loading"
      />
    </CustomLink>
    <Button
      v-if="info?.button"
      :to="buttonLink.to"
      :href="buttonLink.href"
      class="dynamic-banner__button"
      :theme="themeButton"
      :class="{
        [`dynamic-banner__button--${info?.button.location}`]: true,
      }"
    >
      {{ info?.button.text }}
    </Button>
  </div>
</template>

<script>
import * as amplitudeTracker from '@amplitude/analytics-browser';
import Button from '@/components/elements/Button';
import CustomLink from '~/components/elements/CustomLink';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'Banner',
  components: {
    CustomImage,
    CustomLink,
    Button,
  },
  props: {
    info: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    const defaultRatio = {
      mobile: '100%',
      tablet: '27.1%',
      pc: '27.1%',
    }

    const defaultSizes = {
      mobile: {
        width: 360,
        height: 360,
      },
      tablet: {
        width: 1200,
        height: 325,
      },
      pc: {
        width: 1200,
        height: 325,
      },
      ...this.sizes,
    }

    const mediaImage = [
      {
        id: this.info?.mobileImageId,
        width: defaultSizes.mobile.width,
        height: defaultSizes.mobile.height,
        media: '(max-width:574.98px)',
      },
    ]

    if (this.info?.tabletImageId) {
      mediaImage.push({
        id: this.info?.tabletImageId,
        width: defaultSizes.tablet.width,
        height: defaultSizes.tablet.height,
        media: '(max-width:1023px)',
      })
    }

    return {
      mediaImage: mediaImage.filter(item => item.id),

      bannerRatio: {},
      bannerSizes: defaultSizes,
      defaultRatio,
      defaultSizes,

      image: {
        id: this.info?.desktopImageId,
        sizes: [defaultSizes.pc.width, defaultSizes.pc.height],
        ratio: defaultRatio.pc,
      },
    }
  },
  computed: {
    themeButton() {
      return this.info?.button.type
    },
    isExternalLink() {
      return typeof this.info?.url === 'string' && this.info?.url.startsWith('http')
    },
    buttonLink() {
      return {
        to: this.isExternalLink ? '' : this.info?.url,
        href: this.isExternalLink ? this.info?.url : '',
      }
    },
    mqIsMobileXs() {
      return this.$mq === 'mobileXs';
    },
    mqIsTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },
    bannerStyle() {
      return {
        '--banner-ratio-pc': this.bannerRatio.pc,
        '--banner-ratio-tablet': this.bannerRatio.tablet,
        '--banner-ratio-mobile': this.bannerRatio.mobile,
      }
    },
  },
  watch: {
    '$mq': {
      handler(value) {
        switch (value) {
          case 'mobileXs':
            if (this.mediaImage.length) {
              this.setMediaSizes(this.info?.mobileImageId, {
                width: this.bannerSizes.mobile.width,
                height: this.bannerSizes.mobile.height,
              })

              this.image.sizes = [ this.bannerSizes.mobile.width, this.bannerSizes.mobile.height ]
              this.image.ratio = this.bannerRatio.mobile
            }
            break;
          case 'tablet':
          case 'mobile':
            if (this.mediaImage.length) {
              this.setMediaSizes(this.info?.tabletImageId, {
                width: this.bannerSizes.tablet.width,
                height: this.bannerSizes.tablet.height,
              })

              this.image.sizes = [ this.bannerSizes.tablet.width, this.bannerSizes.tablet.height ]
              this.image.ratio = this.bannerRatio.tablet
            }
            break;
          default:
            this.image.sizes = [ this.bannerSizes.pc.width, this.bannerSizes.pc.height ]
            this.image.ratio = this.bannerRatio.pc
        }
      },
      immediate: true,
    },
  },
  created() {
    const newSizes = {
      mobile: this.sizes.mobile ?? ({
        width: parseInt(this.ratio.mobile) > 27
          ? this.defaultSizes.mobile.width : (this.defaultSizes.mobile.height * (100 / (parseInt(this.ratio.mobile)))),
        height: this.defaultSizes.mobile.height,
      }),
      tablet: this.sizes.tablet ?? ({
        width: parseInt(this.ratio.tablet) > 27
          ? this.defaultSizes.tablet.width : (this.defaultSizes.tablet.height * (100 / (parseInt(this.ratio.tablet)))),
        height: this.defaultSizes.tablet.height,
      }),
      pc: this.sizes.pc ?? ({
        width: parseInt(this.ratio.pc) > 27
          ? this.defaultSizes.pc.width : (this.defaultSizes.pc.height * (100 / (parseInt(this.ratio.pc)))),
        height: this.defaultSizes.pc.height,
      }),
    }
    Object.assign(this.bannerRatio, this.defaultRatio, this.ratio)
    Object.assign(this.bannerSizes, this.defaultSizes, newSizes )
  },
  beforeMount() {
    if (this.mqIsTablet) {
      this.image.sizes = [ this.bannerSizes.tablet.width, this.bannerSizes.tablet.height ]
      this.image.ratio = this.bannerRatio.tablet
    } else if (this.mqIsMobileXs) {
      this.image.sizes = [ this.bannerSizes.mobile.width, this.bannerSizes.mobile.height ]
      this.image.ratio = this.bannerRatio.mobile
    } else {
      this.image.sizes = [ this.bannerSizes.pc.width, this.bannerSizes.pc.height ]
      this.image.ratio = this.bannerRatio.pc
    }
  },
  methods: {
    setMediaSizes(id, sizes) {
      const mediaId = this.mediaImage.findIndex(item => item.id === id)

      if (mediaId !== -1) {
        this.$set(this.mediaImage[mediaId], 'width', sizes.width)
        this.$set(this.mediaImage[mediaId], 'height', sizes.height)
      }
    },
    selectItemEvent(info, index) {
      if (info) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_promotion',
          ecommerce: {
            creative_name: info.name,
            promotion_name: 'banners',
          },
        });
        amplitudeTracker.track('click_banner', {
          banner_name: info.name,
          banner_position: `${index + 1}`,
        });
      }
    },
  },
}
</script>
